@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 18px;
}

/* Default grid, as used by the Article field type */
.article-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;
}

.article-grid + .article-grid {
    margin-top: 1rem;
}

.article-grid figure, .article-grid img {
    margin: 0;
    width: 100%;
    max-width: 100%;
}

.article-grid .col-1 {
    grid-column: span 1;
}

.article-grid .col-2 {
    grid-column: span 2;
}

.article-grid .col-3 {
    grid-column: span 3;
}

.article-grid .col-4 {
    grid-column: span 4;
}

.article-grid .col-5 {
    grid-column: span 5;
}

.article-grid .col-6 {
    grid-column: span 6;
}

.article-grid .col-7 {
    grid-column: span 7;
}

.article-grid .col-8 {
    grid-column: span 8;
}

.article-grid .col-9 {
    grid-column: span 9;
}

.article-grid .col-10 {
    grid-column: span 10;
}

.article-grid .col-11 {
    grid-column: span 11;
}

.article-grid .col-12 {
    grid-column: span 12;
}

.article-grid div p {
    margin-top: 0;
}

@media only screen and (max-width: 600px) {
    .article-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .article-grid .col-1,
    .article-grid .col-2,
    .article-grid .col-3,
    .article-grid .col-4,
    .article-grid .col-5,
    .article-grid .col-6,
    .article-grid .col-7,
    .article-grid .col-8,
    .article-grid .col-9,
    .article-grid .col-10,
    .article-grid .col-11,
    .article-grid .col-12 {
        grid-column: span 1;
        margin-bottom: 1rem;
    }
}

@layer utilities {
    @responsive {

        .article > p {
            margin: 1rem 0;
            font-size: clamp(1.1rem, 1.1vw, 1.6rem);
        }

        .article > ol {
            list-style-type: decimal;
            list-style-position: inside;
            font-size: clamp(1.1rem, 1.1vw, 1.6rem);
        }

        .article > ul {
            list-style-type: disc;
            list-style-position: inside;
            font-size: clamp(1.1rem, 1.1vw, 1.6rem);
        }

        .article h1 {
            font-size: clamp(1.875rem, 2.5vw, 2.25rem)
        }

        .article h2 {
            font-size: clamp(1.5rem, 2vw, 1.875rem)
        }

        .article h3 {
            font-size: clamp(1.25rem, 1.75vw, 1.5rem);
            font-weight: 700;
        }

        .article h4 {
            font-size: clamp(1.125rem, 1.5vw, 1.25rem)
        }

        .article h5 {
            font-size: 1rem;
            font-weight: 700;
        }

        .article h6 {
            font-size: 1rem;
            font-weight: 600;
        }

        .article blockquote > p {
            border-left-width: 4px;
            border-color: #b2f5ea;
            font-style: italic;
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-left: 2rem;
            font-size: clamp(1.875rem, 2.5vw, 2.25rem);
        }

    }
}

/* Base grid for "Article" powered Fields */

.h-96 {
    height: 24rem;
}

.max-h-75-screen {
    max-height: 75vh;
}

@media only screen and (max-width: 768px) {
    .white-bottom {
        border-bottom: 0.1rem solid white;
        box-shadow: 0 2px 3px 0 rgb(0 0 0 / 25%), 0 1px 2px 0 rgb(0 0 0 / 50%)
    }

    .bg-white .white-bottom {
        border-bottom-width: 0;
        box-shadow: none;
    }
}

.article p {
    text-align: justify;
}

.article > *:not(.pagination) a:not(.gradient) {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.header-image {
    object-fit: cover;
    object-position: 50% 80%;
}
